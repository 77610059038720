"use client";

import { useLocale } from "next-intl";
import { Locale, locales, authLocaleNames } from "@evenli-platform/core/utils/constants";
import { useRouter } from "next/navigation";
import { useState } from "react";
import { setUserLocale } from "@/lib/server/locale";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/onboarding/select-onboarding";

// HOOK THAT EXECUTES THE SERVER SIDE FUNCTION TO CHANGE THE LOCALE
function useLocaleSwitching() {
  const router = useRouter();
  const [isLoading, setIsLoading] = useState(false);
  const switchLanguage = async (newLocale: Locale) => {
    setIsLoading(true);
    try {
      await setUserLocale(newLocale);
      router.refresh(); // This will trigger a soft refresh of the page
    } finally {
      setIsLoading(false);
    }
  };
  return { switchLanguage, isLoading };
}

const AuthLanguageSelect = () => {
  const currentLocale = useLocale();
  const { switchLanguage, isLoading } = useLocaleSwitching();

  return (
    <Select
      value={
        currentLocale && locales.includes(currentLocale as Locale)
          ? currentLocale
          : ""
      }
      disabled={isLoading}
      onValueChange={(value) => {
        const selectedLanguage = locales.find((language) => language === value);
        if (selectedLanguage) {
          switchLanguage(selectedLanguage);
        }
      }}
    >
      <SelectTrigger className="flex justify-center gap-1 w-fit h-fit rounded-md bg-white/10 font-normal border-none focus:ring-0 focus:ring-offset-0">
        <SelectValue placeholder="Select language" />
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          {locales.map((language) => (
            <SelectItem value={language} key={language}>
              {authLocaleNames[language]}
            </SelectItem>
          ))}
        </SelectGroup>
      </SelectContent>
    </Select>
  );
};

export default AuthLanguageSelect;
